<template>
  <div class="error-container">
    <div style="padding: 0px 100px;box-sizing: border-box">
      <div class="flex-row" style="margin-top: 30px;align-items: center">
        <img src="../assets/go_home.png" style="width: 44px;height: 44px;cursor: pointer" @click="goHome">
        <p class="error-list">Student Wrong Answers</p>
      </div>
      <div style="margin-top: 40px;width: 100%;padding-left:84px;box-sizing: border-box">

        <el-collapse v-model="activeName" accordion>
          <el-collapse-item v-for="(item,index) in dataList"
                            :name="''+(index+1)" :key="index" style="margin-bottom: 20px">
            <template slot="title">
              <div class="error-item">
                <p class="error-item-title">{{ item[0].class_name + " >> " + item[0].session_name }}</p>
                <p class="error-item-question" style="margin-top: 25px">{{ item[0].question_title }}</p>
                <div style="margin-top: 25px;" class="flex-row">
                  <p class="error-item-title"> {{ item.length }} students in total：</p>
                  <div v-for="(child,childIndex) in item" :key="childIndex"
                       style="display: flex;flex-direction: row;align-items: center;flex-wrap: wrap;">
                    <p class="error-item-student">{{
                        child.student.student_lastName + child.student.student_givenName
                      }}</p>
                  </div>
                </div>
              </div>
            </template>
            <div style="margin-top: 10px;width: 100%;padding-left: 40px;box-sizing: border-box">
              <ErrorOptionView :quiz-detail="item[0].quiz_record"></ErrorOptionView>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import {Loading} from "element-ui";
import {getOGStudentErrorStatistics} from '../api/fbla'
import {getUserId} from "../utils/store";
import ErrorOptionView from "../components/ErrorOptionView";

export default {
  name: "ErrorStatistics",
  components: {ErrorOptionView},
  data() {
    return {
      activeName: '1',
      user_id: getUserId(),
      dataList: []
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      getOGStudentErrorStatistics(this.user_id).then((res) => {
        this.dataList = res.data.data
      })
    },
    goHome() {
      this.$router.go(-1)
    },
    showLoadingView() {
      this.closeLoading()
      this.loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.8)',
        spinner: 'el-icon-loading',
        text: 'Loading'
      });
    },
    closeLoading() {
      if (this.loadingInstance) {
        this.loadingInstance.close()
      }
    },
  }
}
</script>

<style scoped lang="less">


.error-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: 1920px;
  height: 92vh;
  overflow-y: auto;
}

.error-list {
  margin-left: 40px;
  font-size: 28px;
  font-family: AlibabaPuHuiTi-2-75-SemiBold;
  font-weight: 600;
  color: #092E7F
}

.error-item {
  //margin-bottom: 20px;
  //width: 100%;
  //background: #F1F6FF;
  //box-shadow: 0px 3px 6px 1px rgba(9, 46, 127, 0.08);
  //border-radius: 10px 10px 10px 10px;
  //padding: 30px 40px;
  //box-sizing: border-box;
}

.error-item-title {
  font-size: 16px;
  font-family: AlibabaPuHuiTi-2-75-SemiBold;
  font-weight: 600;
  color: #666666;
}

.error-item-question {
  font-size: 18px;
  font-family: AlibabaPuHuiTi-2-75-SemiBold;
  font-weight: 600;
  color: #092E7F;
  line-height: 32px;
}

.error-item-student {
  padding: 6px 9px;
  box-sizing: border-box;
  background: #FFF2D9;
  border-radius: 10px 10px 10px 10px;
  font-size: 16px;
  font-family: AlibabaPuHuiTi-2-75-SemiBold;
  font-weight: 600;
  color: #FFA900;
  margin-bottom: 15px;
  margin-right: 15px;
}


/deep/ .el-collapse {
  border-bottom-color: transparent;
  border-top-color: transparent;
}

/deep/ .el-collapse.is-active {
  border-bottom-color: transparent;
  border-top-color: transparent;
}

/deep/ .el-collapse-item__header {
  padding: 20px 20px;
  box-sizing: border-box;
  height: unset;
  min-height: 60px;
  box-shadow: 0px 3px 6px 1px rgba(9, 46, 127, 0.08);
  border-radius: 10px;
  display: flex;
  align-items: center;
  background-color: #F2F6FF;
  color: #092E7F;
  cursor: pointer;
  border-bottom-width: 0px;
  border-bottom-color: transparent;
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  transition: border-bottom-color .3s;
  outline: 0;
}

/deep/ .el-collapse-item__header.is-active {
  border-bottom-color: transparent;
}
</style>
